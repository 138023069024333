@import "./breakpoints";

.font-size-1_25 {
  font-size: 1.25rem;
}

.p-0_5 {
  padding: 0.5rem;
}

.p-1_0 {
  padding: 1rem;
}

.p-1_5 {
  padding: 1.5rem;
}
.py-0_5 {
  padding: 0 0.5rem;
}

.px-1_0 {
  padding: 1.0rem 0;
}

.px-1_5 {
  padding: 1.5rem 0;
}

.pt-1_0 {
  padding-top: 1rem;
}
 
.mx-2 {
  margin: 0 0.5rem;
}

.my-2 {
  margin: 0.5rem 0;
}

.mt-1_0 {
  margin-top: 1rem;
}

.m-0-auto {
  margin: 0 auto;
}

.opacity-0_80 {
  opacity: 0.8;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 35%;
}

.col-45 {
  width: 45%;
}

.col-50 {
  width: 50%;
}

.col-55 {
  width: 55%;
}

.col-60 {
  width: 65%;
}

.col-65 {
  width: 65%;
}

.col-20-25 {
  width: 20%;
}

.col-30-25 {
  width: 30%;
}

.border {
  border: 1px solid white
}

.justify-content-center {
  justify-content: center;
}

.accent-section-header {
  color: $primary
}

.dark-section-header {
  color: $accent
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  padding-bottom: 1.25rem;
}

h3 {
  font-size: 1.05rem;
}

p {
  font-family: 'Montserrat-Light', serif;
}

.show {
  display: block;
  transition: all 0.6s ease-in-out;
}

.hide {
  color: transparent;
  background: transparent;
  transition: all 0.6s ease-in-out;
}

.remove {
  display: none;
  transition: all 0.6s ease-in-out;
}

.primary {
  color: $primary;
}

.accent_2 {
  color: $accent_2;
}

.uppercase {
  text-transform : uppercase 
}

@media screen and (min-width: $sm) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
    padding-bottom: 1.75rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: $md) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.05rem;
  }


}

@media screen and (min-width: $lg) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.25rem;
    padding-bottom: 2.25rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  p {
    font-size: 1.25rem;
  }

  .col-20-25 {
    width: 25%;
  }
  
  .col-30-25 {
    width: 25%;
  }
  
}

@media screen and (min-width: $xl) {

}

// semantic UI overwrites
.ui.styled.accordion, .ui.styled.accordion .accordion {
  border-radius: 0 !important; 
  background: transparent !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.ui.styled.accordion .accordion .title, .ui.styled.accordion .title,
.ui.styled.accordion .active.title {
  font-size: 1.5rem !important;
}