@import "assets/config/normalize";
@import "assets/config/resets";
@import "assets/config/colors";
@import "assets/config/breakpoints";
@import "assets/config/styleguide";

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Bold'), url('./assets/fonts/Montserrat-Bold.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Light'), url('./assets/fonts/Montserrat-Light.otf') format('truetype');
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100vh;
  background: linear-gradient(
    63deg,
    $dark 0%,
    $accent 33%,
    $secondary 90%,
    $primary 100%
  );
  font-family: 'Montserrat', serif;
  font-size: 14px;
  letter-spacing: 0.1rem;
  color: $accent;
  
}



