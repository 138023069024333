@import "../../../assets/config/colors";
@import "../../../assets/config/breakpoints";

.lang__switch_container {
  position: relative;
}

.lang__switch {
  width: 120px;
  cursor: pointer;
}

.lang__switch_label {
  display: none;
}

.lang__switch_list {
  text-align: right;
  background: $secondary;
  box-shadow: $box-shadow;
  position: absolute;
  top: 25px;
  right: -10px;
}

.lang__switch_list_item {
  border-bottom: $border-width solid $accent;
}

.lang__switch_list_item:hover,
.lang__switch_list_item_active {
  color: #fff;
  background-color: $accent;
}

@media screen and (min-width: $md) {
  .lang__switch,
  .lang__switch_list_item {
    font-size: 1.25rem;
  }

  .lang__switch_label {
    display: block;
    margin-top: 0.25rem;
  }
}

