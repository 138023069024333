
$enable-grid-classes:false;

$success:#083358;
$danger:#FF4057;
$info:#74DBEF;
$warning:#FC3C3C;


$spacer:1rem;

$border-width:1px;
$btn-border-radius:.25rem;
$box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);

$dark:#000;
$accent:#262626;
$accent_2: #ffbf00;
$primary:#FFD294;
$secondary:#7F7F7f;
$light:#F2F2F0;
$boxes:#a5a5a5

